import { Option } from '@ptg-shared/controls/select/select.component';
import { OverpaidStatus, PaymentTransactionStatus, TransactionType } from './services/models/register.model';
import { PaymentType } from '@ptg-processing/features/payroll-calendar-container/types/enums/payroll-deduction-payee.enum';

export const MODULE_KEY = 'transactionRegisterModule';

export const TransactionTypeOptions: Option[] = [
  { value: TransactionType.Recurring, displayValue: 'Recurring' },
  { value: TransactionType['One-Time'], displayValue: 'One-Time' },
  { value: TransactionType.Adjustment, displayValue: 'Adjustment' },
  { value: TransactionType['Deduction Payee'], displayValue: 'Deduction Payee' },
];

export const PaymentTypeOptions: Option[] = [
  { value: PaymentType.Check, displayValue: 'Check' },
  { value: PaymentType['Direct Deposit'], displayValue: 'Direct Deposit' },
  { value: PaymentType['Internal Payment'], displayValue: 'Internal Payment' },
  { value: PaymentType['Wire Transfer'], displayValue: 'Wire Transfer' },
];

export const TransactionStatusOptions: Option[] = [
  { value: PaymentTransactionStatus.Created, displayValue: 'Created' },
  { value: PaymentTransactionStatus.Posted, displayValue: 'Posted' },
  { value: PaymentTransactionStatus.Pending, displayValue: 'Pending' },
  { value: PaymentTransactionStatus['Voided - Not Reissued'], displayValue: 'Voided - Not Reissued' },
  { value: PaymentTransactionStatus['Voided - Processing Reissue'], displayValue: 'Voided - Processing Reissue' },
  { value: PaymentTransactionStatus['Voided - Reissued'], displayValue: 'Voided - Reissued' }
];

export const OverpaidOptions: Option[] = [
  { value: OverpaidStatus.Yes, displayValue: 'Yes' },
  { value: OverpaidStatus.No, displayValue: 'No' },
];
